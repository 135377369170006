<template>
  <div class="event-single">
    <section class="hero is-info">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">

            <div class="column is-5-tablet is-4-desktop is-3-widescreen">
              <h2 class="subtitle">{{ $t("login.title") }}</h2>
              <div class="box">

                <div class="field">
                  <label for="" class="label">{{ $t("login.email") }}</label>
                  <div class="control has-icons-left">
                    <input type="email" placeholder="e.g. user@test.com" class="input" v-model="email" required>
                    <span class="icon is-small is-left">
                      <i class="fa fa-envelope"></i>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <label for="" class="label">{{ $t("login.password") }}</label>
                  <div class="control has-icons-left">
                    <input type="password" placeholder="*******" class="input" v-model="password" required>
                    <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
                  </div>
                </div>
                <div class="field">
                  <label for="" class="checkbox">
                    <input type="checkbox" v-model="remember">
                    {{ $t("login.remember") }}
                  </label>
                </div>
                <div class="field">
                  <button class="button is-success" @click="userlogin()">
                    {{ $t("login.login") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>


</template>

<script>

import { LoginRequest } from '../pb/auth_service_pb'
import { AuthClient } from '../pb/auth_service_grpc_web_pb'
import {UserClient} from "../pb/user_service_grpc_web_pb";
import {GetUserRequest} from "../pb/user_service_pb";
import common from "@/utility/common"

export default {
  name: 'Login',
  data() {
    return {
      email: "",
      password: "",
      user: {},
      remember: false
    }
  },

  methods: {
    userlogin: function () {
      this.client = new AuthClient(common.SECURE_HOST, null, {
        "grpc.max_receive_message_length": common.MESSAGE_LENGTH,
        "grpc.max_send_message_length": common.MESSAGE_LENGTH
      })
      let loginRequest = new LoginRequest()
      loginRequest.setEmail(this.email)
      loginRequest.setPassword(this.password)
      this.client.login(loginRequest, {}, (err, response) => {
        if (err != null) {
          alert("user name password invalid" + err.toString())
          this.email = ""
          this.password = ""
        } else {
          let accessToken = response.toObject().accessToken
          let days = '1d'
          if (this.remember) {
            days = '90d'
          }
          this.$cookies.set('$t', accessToken, days)
          let metadata = {'authorization': accessToken}
          this.client = new UserClient(common.SECURE_HOST, null, {
            "grpc.max_receive_message_length": common.MESSAGE_LENGTH,
            "grpc.max_send_message_length": common.MESSAGE_LENGTH
          })
          let getUserRequest = new GetUserRequest()
          getUserRequest.setEmail(this.email)
          this.client.getUser(getUserRequest, metadata, (err, response)=>{
            if (err != null) {
              alert("Failed to get user: " + err)
              return
            }
            if (response != null) {
              this.user = response.toObject().usersList[0]
              let encryptedUid = this.CryptoJS.AES.encrypt(this.email, "jumbosecret").toString()
              this.$cookies.set('$uid', encryptedUid, '1d')
              this.$root.$emit('updateAuth', true)
              this.$store.commit('setLoggedInUser', this.user)
              this.$router.push(this.$route.query.redirect || '/')
            }
          })
        }
      })
    }
  }
}
</script>
